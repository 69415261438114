import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import InfiniteScroll from 'arts/components/InfiniteScroll';
import AliceCarousel from 'react-alice-carousel';
import Loader from 'arts/components/Loader';
import { usePrevious } from 'arts/config/hooks';
import AppClasses from '../Application/styles';
import Classes from './styles';
import Item from './item';
import Filter from '../works/filter';
import GTMPageView from '../Application/data_layer';
import { scheduleProductView } from '../../config/api';
import { trackEvent, onOriginalArtClick } from '../../config/analytics';
import { handleDragStart } from '../slider_arrows';
import { humanizeParams } from '../works/works';

const onItemClick = (opts) => {
  scheduleProductView(onOriginalArtClick(opts));
};

const renderItem = (item, position) => (
  <Item { ...item } key={ item.id } position={ position } onClick={ onItemClick } />
);

const VALID_QUERY_PARAMS = ['with_original_category_id', 'with_original_style_id'];

const clearFilters = (params) => {
  const out = {};
  VALID_QUERY_PARAMS.forEach(key => {
    const val = params[key];
    if (val) {
      out[key] = val;
    }
  });

  return out;
};

const OriginalArts = ({
  collection,
  loading,
  hasMore,
  fetchOriginalArts,
  resetOriginalArts,
  fetchOriginalArtsFilters,
  originalArtFilters,
  skipBanner,
  children,
  history
}) => {
  const params = queryString.parse(window.location.search);
  const activeFilters = clearFilters(params);

  const prevProps = usePrevious({ collection });

  const [page, setPage] = useState(collection.length / 12);

  const items = useMemo(() => [
    <div><img src="https://d2ie288hwu80n0.cloudfront.net/assets/original-art/oa-1.jpg" alt="Original Art" onDragStart={ handleDragStart } /></div>,
    <div><img src="https://d2ie288hwu80n0.cloudfront.net/assets/original-art/oa-2.jpg" alt="Original Art" onDragStart={ handleDragStart } /></div>,
    <div><img src="https://d2ie288hwu80n0.cloudfront.net/assets/original-art/oa-3.jpg" alt="Original Art" onDragStart={ handleDragStart } /></div>
  ], []);

  const renderBanner = useMemo(() => (
    skipBanner ? null : (
      <>
        <div className={ Classes.banner }>
          <AliceCarousel
            animationDuration={ 400 }
            autoPlayStrategy="action"
            autoPlay
            autoPlayInterval={ 3000 }
            disableButtonsControls
            disableDotsControls
            disableSlideInfo
            infinite
            mouseTracking
            items={ items }
            key={ (new Date()).getTime() }
          />
        </div>
        <h2 className={ Classes.happy }>
          <div>
            Enjoy our capsule of one-of-a-kind original art! Each work was
            hand-picked by our curators for its unique personality and style,
            and is meant to add a touch of magic to your collection.
          </div>
        </h2>
      </>
    )
  ), [skipBanner]);

  const doFetch = () => {
    if (hasMore && !loading) {
      fetchOriginalArts(page, activeFilters);
    }
  };

  useEffect(() => {
    if (!originalArtFilters && fetchOriginalArtsFilters) {
      fetchOriginalArtsFilters();
    }
  }, []);

  const resetOriginalArtsFilter = () => {
    if (resetOriginalArts) {
      setPage(0);
      setTimeout(() => {
        resetOriginalArts();
      }, 0);
    }
  };

  useEffect(() => {
    const prevCollection = (prevProps || {}).collection || [];
    if (collection.length > prevCollection.length) {
      setPage(page + 1);
    }
  }, [collection]);

  useEffect(() => {
    if (!loading && collection.length && originalArtFilters) {
      const humanParams = humanizeParams(activeFilters, originalArtFilters);
      trackEvent('original art center', {
        ...humanParams,
        'original art count': collection.length
      });
    }
  }, [loading, collection.length, originalArtFilters]);

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="category" section="original art" />
      { renderBanner }
      {
        history ? (
          <Filter
            path="/original_arts"
            label="Search original art by"
            history={ history }
            { ...activeFilters }
            filters={ VALID_QUERY_PARAMS }
            artFilters={ originalArtFilters }
            onChange={ resetOriginalArtsFilter }
          />
        ) : null
      }
      { children }
      <InfiniteScroll
        items={ collection }
        itemRenderer={ renderItem }
        onLoad={ doFetch }
        rootClassName={ Classes.infiniteScrollRoot }
        threshold={ 728 }
      />
      {
        loading
          ? <Loader text="Loading more items, hold on for a sec..." />
          : null
      }
    </div>

  );
};

OriginalArts.propTypes = {
  collection: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  fetchOriginalArts: PropTypes.func,
  skipBanner: PropTypes.bool,
  children: PropTypes.node,
  originalArtFilters: PropTypes.arrayOf(PropTypes.any),
  resetOriginalArts: PropTypes.func,
  fetchOriginalArtsFilters: PropTypes.func,
  history: ReactRouterPropTypes.history
};

OriginalArts.defaultProps = {
  fetchOriginalArts: null,
  skipBanner: false,
  children: null
};

export default OriginalArts;
