import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { updateCollectionStoreItems } from '../utils';

const getDefaultState = () => ({
  loading: false,
  error: null,
  collection: [],
  page: 1,
  totalPages: -1
});

const works = createReducer(getDefaultState(), {
  [Constants.Works.FETCH](state, { params }) {
    return {
      ...state,
      error: null,
      loading: true,
      params
    };
  },

  [Constants.Works.FETCH_SUCCESS](state, { data, page, totalPages }) {
    return {
      ...state,
      loading: false,
      page,
      totalPages,
      collection: data
    };
  },

  [Constants.Works.FETCH_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      error: action.errors ? action.errors.message : ''
    };
  },

  [Constants.Works.RESET]() {
    return getDefaultState();
  },

  [Constants.WorkStoreItems.ADDED](state, { workId, storeItemId }) {
    return {
      ...state,
      collection: updateCollectionStoreItems(state.collection, workId, storeItemId)
    };
  },

  [Constants.WorkStoreItems.REMOVED](state, { workId }) {
    return {
      ...state,
      collection: updateCollectionStoreItems(state.collection, workId, null)
    };
  }
});

export default works;
export * from './proptypes';
