import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import numeral from 'arts/config/numeral';
import { getCartItems } from 'arts/config/api';
import { MODAL_SELECTOR } from 'arts/config/constants';
import AppClasses from '../Application/styles';
import { FORMAT } from '../checkout_summary/checkout_summary';
import Classes from './styles';
import GTMPageView from '../Application/data_layer';
import { toCamelCase } from '../../config/convertCase';
import { onOrderPlaced } from '../../config/analytics';
import Modal from '../Modal';
import Subscribe from './subscribe';

const ThankYou = () => {
  const ref = useRef(null);
  const { id } = useParams();
  const [thanks, setThanks] = useState(window.thankYou || {});
  const { cartItems, placedOrder } = toCamelCase(window.purchasedCartItems || {});
  const analyticsEventId = window.analyticsEventId || null;
  const customerEmailSHA256 = window.customerEmailSHA256 || '';
  const [registeredVisible, setShowRegistered] = useState(false);
  const {
    orderId, userEmail, placedAt, total, method,
    shipping, locale, guestSignupToken, remindToSubscribe
  } = thanks;

  const showRegistered = () => setShowRegistered(true);
  const hideRegistered = () => setShowRegistered(false);

  if (locale) { numeral.locale(locale); }

  const go = (e, eventName) => {
    window.after_auth = () => {
      showRegistered();
    };
    e.preventDefault();
    const event = new CustomEvent(eventName);
    document.dispatchEvent(event);
  };

  useEffect(() => {
    if (!orderId) {
      getCartItems(id).then(response => {
        if (ref.current) {
          const { summaryOfCharges } = response || {};
          const { thankYou } = summaryOfCharges || {};
          setThanks(thankYou);
        }
      }).catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (cartItems && placedOrder) {
      onOrderPlaced(cartItems, placedOrder, customerEmailSHA256, analyticsEventId);
    }
  }, [placedOrder]);

  const onSignUpClicked = (e) => {
    document.querySelector('#register_form input[type=email]').value = userEmail;
    go(e, 'LoginRequired');
    setTimeout(() => {
      document.querySelector('#register_form input[type=password]').focus();
    }, 100);
  };

  const signUp = guestSignupToken ? (
    <div className={ classnames(Classes.print, Classes.signUp) }>
      <div>Create an account for easier checkout and access to orders history.</div>
      <div>
        <button type="button" className={ Classes.button } onClick={ onSignUpClicked }>
          CREATE AN ACCOUNT
        </button>
      </div>
    </div>
  ) : null;

  const subscribeReminder = remindToSubscribe ? (
    <Subscribe email={ userEmail } customerEmailSHA256={ customerEmailSHA256 } />
  ) : null;

  const delimiter = (signUp || subscribeReminder) ? (
    <div className={ Classes.signUpDelimiter }>
      <div />
    </div>
  ) : null;

  const onTrackYourOrder = () => {
    window.location.href = `/my/history_order/${ id }`;
  };

  const modal = registeredVisible ? (
    <Modal
      selector={ MODAL_SELECTOR }
      onCancel={ hideRegistered }
      className={ AppClasses.thanksForAction }
    >
      <h2>
        Thanks for opening an account
        <br />
        with Artfully Walls!
      </h2>
      <p>
        You can now track your order details by logging into your account,
        and going to the Order History tab.
      </p>
      <div className={ Classes.div } />
      <div className={ Classes.action }>
        <button type="button" className={ Classes.button } onClick={ onTrackYourOrder }>
          TRACK YOUR ORDER
        </button>
      </div>
    </Modal>
  ) : null;

  const content = orderId ? (
    <div className={ classnames(AppClasses.innerContainer20, Classes.thankyou) }>
      <GTMPageView pageType="checkout" section="checkout" key={ orderId } />
      <h1>
        Thank you so much for placing your order
        <em />
      </h1>
      { signUp }
      { subscribeReminder }
      { delimiter }
      <div className={ Classes.print }>
        An order confirmation will be sent to your email address&nbsp;
        <strong>
          { userEmail }
        </strong>
        &nbsp;shortly.
        <br />
        In the meanwhile, please print the details below for your records.
      </div>
      <div className={ classnames(Classes.print, Classes.info) }>
        <div>
          Order number:
          <br />
          Order placed:
          <br />
          Order total:
          <br />
          Payment method:
          <br />
          Shipping method:
        </div>
        <div>
          { orderId }
          <br />
          { placedAt }
          <br />
          { numeral(total).format(FORMAT) }
          <br />
          { method }
          <br />
          { shipping }
        </div>
      </div>
      <div className={ Classes.signUpDelimiter }>
        <div />
      </div>
      <Link to="/my/referral" className={ Classes.more }>
        <img src="https://d2ie288hwu80n0.cloudfront.net/assets/give-n-get2.jpg" alt="Give and Get" />
      </Link>
      { modal }
    </div>
  ) : (
    <div>
      <Loader active inline style={ { marginTop: 24 } } />
    </div>
  );

  return (
    <div className={ AppClasses.container20 } ref={ ref }>
      { content }
    </div>
  );
};

export default ThankYou;
