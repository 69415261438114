import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const MAT_NAMES = {
  0: 'fullBleed',
  3: 'matte'
};

const denormalize = ({ workSizes = [], ...rest }) => ({
  ...rest,
  workSizes: workSizes.map(paper => ({
    ...paper,
    options: paper && paper.options.map(frame => ({
      ...frame,
      matName: MAT_NAMES[frame.frameMat]
    }))
  })).filter(v => v.options)
});

const findFrameById = (workSizes, frameId) => {
  for (let i = 0, l = workSizes.length; i < l; i += 1) {
    for (let f = 0, fl = workSizes[i].options.length; f < fl; f += 1) {
      const option = workSizes[i].options[f];
      if (option.frameId === frameId && option.zoomPreviewUrl) {
        return [workSizes[i], option];
      }
    }
  }
  return [null, null];
};

const findAnyFrame = (workSizes, mat) => {
  for (let i = 0, l = workSizes.length; i < l; i += 1) {
    for (let f = 0, fl = workSizes[i].options.length; f < fl; f += 1) {
      const option = workSizes[i].options[f];
      if (option.frameMat === mat && option.zoomPreviewUrl) {
        return [workSizes[i], option];
      }
    }
  }
  return [null, null];
};

const parseThumbnails = (data, previewFrame) => {
  const res = [{
    type: 'image',
    url: data.imageUrl
  }];

  let frame = null;
  let paper;
  if (data.workSizes && data.workSizes.length > 0) {
    res[0].selection = {
      paper: data.workSizes[0],
      frame: data.workSizes[0] && (data.workSizes[0].options || [])[0]
    };
    if ((previewFrame || {}).frameId) {
      [paper, frame] = findFrameById(data.workSizes, previewFrame.frameId);
    }
    if (!frame) {
      [paper, frame] = findAnyFrame(data.workSizes, 3);
    }
    if (!frame) {
      [paper, frame] = findAnyFrame(data.workSizes, 0);
    }
    if (paper && frame) {
      res.push({ type: 'framed', url: frame.zoomPreviewUrl, selection: { paper, frame } });
    }
  }

  if (data.secondPreviewUrl) {
    res.push({
      type: 'second',
      url: data.secondPreviewUrl
    });
  }

  if (previewFrame && previewFrame.framePreviewUrl) {
    res.push({
      type: 'frame',
      url: previewFrame.framePreviewUrl
    });
  } else if (data.canvasPreviewUrl) {
    res.push({
      type: 'canvas',
      url: data.canvasPreviewUrl
    });
  }

  return res;
};

const getDefaultState = () => ({
  loading: false,
  error: null,
  data: {},
  thumbnails: []
});

const work = createReducer(getDefaultState(), {
  [Constants.Work.FETCH](state, { id, detail }) {
    const partialData = denormalize(detail);
    return {
      ...state,
      id,
      loading: true,
      data: partialData,
      thumbnails: []
    };
  },

  [Constants.Work.FETCH_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },

  [Constants.Work.FETCH_SUCCESS](state, action) {
    const data = denormalize(action.data);
    if (state.id !== data.id) { return state; }
    if (data.notAvailable && !data.isHidden) {
      return { ...state, loading: false, data: { ...state.data, notAvailable: true } };
    }
    return {
      loading: false,
      data,
      thumbnails: parseThumbnails(data)
    };
  },

  [Constants.WorkStoreItems.ADDED](state, { workId, storeItemId }) {
    if (state.data.id !== workId) { return state; }
    return {
      ...state,
      data: {
        ...state.data,
        storeItemId
      }
    };
  },

  [Constants.WorkStoreItems.REMOVED](state, { workId }) {
    if (state.data.id !== workId) { return state; }
    return {
      ...state,
      data: {
        ...state.data,
        storeItemId: null
      }
    };
  },

  [Constants.Selection.SET_FRAME](state, { frame }) {
    return { ...state, thumbnails: parseThumbnails(state.data, frame) };
  }

});

export default work;
export * from './proptypes';
