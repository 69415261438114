import { connect } from 'react-redux';
import ExtraInfo from './extra_info';

const mapStateToProps = ({ selection, work }) => ({
  hasSelection: !!selection.frame,
  aboutPrints: work.data && work.data.aboutPrints
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExtraInfo);
