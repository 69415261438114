import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

const ScrollAppearMonitor = ({ onAppear, className, children }) => {
  const [reported, setReported] = useState(false);

  const { ref, inView /* , entry */ } = useInView({
    threshold: 0
  });

  if (!reported && inView && onAppear) {
    setReported(true);
    onAppear();
  }

  return (
    <div className={ className } ref={ ref }>
      { children }
    </div>
  );
};

ScrollAppearMonitor.propTypes = {
  onAppear: PropTypes.func,
  className: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default ScrollAppearMonitor;
