import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { worksShape } from 'arts/reducers/works';
import WorkListItem from '../WorkListItem';
import Filter, { NEW_ARRIVALS } from './filter';
import { BY_COLOR } from './color_filters';
import Paginator from './paginator';
import Classes from './styles';
import GTMPageView from '../Application/data_layer';
import { onWorkItems, onWorkClick, trackEvent } from '../../config/analytics';
import QuickShop from '../QuickShopModal/quick_shop';
import { useWindowSize } from '../visual_search/use_window_size';

import AppClasses from '../Application/styles';

const { w1 } = Classes;

const GTM_BATCH_SIZE = 20;
const GTM_LIST_NAME = 'art-center';

export const WITH_CATEGORY_ID = 'with_category_id';
export const WITH_STYLE_ID = 'with_style_id';
export const WITH_PAPER = 'with_paper';
export const WITH_PRICE = 'with_price';
export const WITH_X_SIZE = 'with_x_size';
export const WITH_ORIENTATION = 'with_orientation';

export const humanizeParams = (queryParams, artFilters) => {
  const res = {};
  Object.keys(queryParams).forEach(key => {
    const filter = (artFilters.find(f => f.key === key) || {});
    const options = filter.options || [];
    if (key === BY_COLOR) {
      res[key] = queryParams[key];
    } else {
      const option = options.find(o => o.id.toString() === queryParams[key]) || {};
      if (option.name) {
        res[filter.title] = option.name;
      } else {
        res[key] = queryParams[key];
      }
    }
  });
  return res;
};

const VALID_QUERY_PARAMS = [
  NEW_ARRIVALS,
  WITH_CATEGORY_ID,
  WITH_STYLE_ID,
  WITH_PAPER,
  WITH_PRICE,
  'query',
  WITH_X_SIZE,
  WITH_ORIENTATION,
  'with_aspect_ratio',
  'page',
  BY_COLOR
];

const getParams = (params, pathname, search) => {
  const parsedParams = queryString.parse(search);
  const out = {};

  VALID_QUERY_PARAMS.forEach(key => {
    const val = parsedParams[key];
    if (val) {
      out[key] = val;
    }
  });

  const cleanParams = { ...out };
  const skipFilterKeys = [];

  const {
    categorySlug,
    styleSlug,
    mediumSlug,
    priceSlug,
    sizeSlug,
    orientationSlug,
    colorSlug
  } = (params || {});

  if (categorySlug) {
    const cid = (window.slugsMaps.categories || {})[categorySlug];
    if (cid) {
      out[WITH_CATEGORY_ID] = cid;
      skipFilterKeys.push(WITH_CATEGORY_ID);
    }
  }

  if (styleSlug) {
    const cid = (window.slugsMaps.styles || {})[styleSlug];
    if (cid) {
      out[WITH_STYLE_ID] = cid;
      skipFilterKeys.push(WITH_STYLE_ID);
    }
  }

  if (mediumSlug) {
    const cid = (window.slugsMaps.mediums || {})[mediumSlug];
    if (cid) {
      out[WITH_PAPER] = cid;
      skipFilterKeys.push(WITH_PAPER);
    }
  }

  if (priceSlug) {
    const cid = (window.slugsMaps.prices || {})[priceSlug];
    if (cid) {
      out[WITH_PRICE] = cid;
      skipFilterKeys.push(WITH_PRICE);
    }
  }

  if (colorSlug) {
    out[BY_COLOR] = colorSlug;
    skipFilterKeys.push(BY_COLOR);
  }

  if (sizeSlug) {
    const cid = (window.slugsMaps.sizes || {})[sizeSlug];
    if (cid) {
      out[WITH_X_SIZE] = cid;
      skipFilterKeys.push(WITH_X_SIZE);
    }
  }

  if (orientationSlug) {
    const cid = (window.slugsMaps.orientations || {})[orientationSlug];
    if (cid) {
      out[WITH_ORIENTATION] = cid;
      skipFilterKeys.push(WITH_ORIENTATION);
    }
  }

  const nMatch = pathname.match(/\/new-arrivals/);
  if (nMatch) {
    out[NEW_ARRIVALS] = true;
    skipFilterKeys.push(NEW_ARRIVALS);
  }

  return [out, cleanParams, skipFilterKeys];
};

const Works = ({
  works, fetchWorksPage, loading,
  error,
  location, history, artFilters, fetchArtFilters,
  page, totalPages
}) => {
  const params = useParams();

  const [mlBannerWorkId, setMlBannerWorkId] = useState(null);
  const { width } = useWindowSize();

  const bannerIndexBase = useMemo(() => (
    width >= parseInt(w1, 10) ? 3 : 2
  ), [width]);

  const bannerIndex = useMemo(() => {
    if (!mlBannerWorkId) {
      return null;
    }
    const bi = works.map(w => w.id).indexOf(mlBannerWorkId);
    if (bi > -1) {
      const candidateIndex = bannerIndexBase + bannerIndexBase * Math.floor(bi / bannerIndexBase);
      if (candidateIndex > works.length - 1) {
        return works.length;
      }
      return candidateIndex;
    }
    return null;
  }, [mlBannerWorkId, bannerIndexBase, works.length]);

  const [
    queryParams, cleanParams, skipFilterKeys
  ] = useMemo(
    () => getParams(params, location.pathname, location.search),
    [location.pathname, location.search]
  );

  useEffect(() => {
    if (!artFilters) {
      fetchArtFilters();
    }
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }
  }, [error]);

  useEffect(() => {
    fetchWorksPage(queryParams);
  }, [location.search, location.pathname]);

  useEffect(() => {
    if (artFilters) {
      const humanParams = humanizeParams(queryParams, artFilters);
      trackEvent('art center', humanParams);
    }
  }, [location.search, location.pathname, artFilters]);

  const loader = loading && works.length === 0 ? (
    <div className={ Classes.loading }>
      <Loader inline active content="Loading Art..." />
    </div>
  ) : null;

  const onAppear = (position) => {
    const items = works.slice(position, position + GTM_BATCH_SIZE);
    onWorkItems({ items, listName: GTM_LIST_NAME, pageType: 'category' });
  };

  const onItemClick = (position) => onWorkClick(works[position], 'category', GTM_LIST_NAME, position);

  return (
    <div>
      <GTMPageView pageType="category" section="art prints" />
      <Filter
        path={ location.pathname }
        label="Search art by"
        history={ history }
        { ...queryParams }
        filters={ VALID_QUERY_PARAMS.filter(p => p !== 'page') }
        artFilters={ artFilters }
        skipFilterKeys={ skipFilterKeys }
        defaultPath="/art-prints"
      />
      <div className={ AppClasses.container20 }>
        <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
          { loader }
          <Paginator
            path={ location.pathname }
            params={ cleanParams }
            page={ page }
            totalPages={ totalPages }
            history={ history }
            className={ Classes.small }
          />
          <QuickShop />
          <ul className={ AppClasses.gridContainer3inRow }>
            {
              works.map((w, index) => (
                <WorkListItem
                  data={ w }
                  key={ w.id }
                  loading={ loading }
                  onAppear={ index % GTM_BATCH_SIZE === 0 ? onAppear : null }
                  position={ index }
                  onClick={ onItemClick }
                  setMlBannerWorkId={ setMlBannerWorkId }
                  mlBannerWorkId={ mlBannerWorkId }
                  bannerIndex={ bannerIndex }
                />
              ))
            }
          </ul>
          <Paginator
            path={ location.pathname }
            params={ cleanParams }
            page={ page }
            totalPages={ totalPages }
            history={ history }
          />
        </div>
      </div>
    </div>
  );
};

Works.propTypes = {
  works: worksShape.isRequired,
  fetchWorksPage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  error: PropTypes.string,
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  artFilters: PropTypes.arrayOf(PropTypes.any),
  fetchArtFilters: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

Works.defaultProps = {
  artFilters: null
};

export default Works;
