import React, { PureComponent } from 'react';
import T from 'prop-types';
import DefaultContainer from './container';

class InfiniteScroll extends PureComponent {
  static propTypes = {
    items: T.instanceOf(Array).isRequired,
    itemRenderer: T.func.isRequired,
    itemsContainer: T.oneOfType([T.element, T.func]),
    onLoad: T.func.isRequired,
    rootClassName: T.string,
    threshold: T.number
  }

  static defaultProps = {
    rootClassName: undefined,
    threshold: 100,
    itemsContainer: DefaultContainer
  }

  constructor() {
    super();

    this.recalculate = this.recalculate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setRootRef = this.setRootRef.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onChange);
    window.addEventListener('scroll', this.onChange);
    this.recalculate();
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (items !== prevProps.items) {
      this.onChange();
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.raf);
    window.removeEventListener('resize', this.onChange);
    window.removeEventListener('scroll', this.onChange);
  }

  onChange() {
    if (this.ticking === true) {
      return;
    }

    this.ticking = true;
    this.raf = window.requestAnimationFrame(this.recalculate);
  }

  setRootRef(el) {
    this.rootEl = el;
  }

  recalculate() {
    const {
      top,
      height
    } = this.rootEl.getBoundingClientRect();

    const {
      scrollY,
      pageYOffset,
      innerHeight
    } = window;

    const { threshold, onLoad } = this.props;

    const sY = scrollY || pageYOffset;
    const rootElBottom = top + sY + height;
    const viewportBottom = sY + innerHeight;

    const shouldLoad = rootElBottom - threshold <= viewportBottom;

    if (shouldLoad) {
      onLoad();
    }

    this.ticking = false;
  }

  render() {
    const {
      rootClassName,
      items,
      itemRenderer,
      itemsContainer
    } = this.props;

    const content = React.createElement(
      itemsContainer,
      null,
      items.map(itemRenderer)
    );

    return (
      <div
        className={ rootClassName }
        ref={ this.setRootRef }
      >
        { content }
      </div>
    );
  }
}

export default InfiniteScroll;
