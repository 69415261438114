import React, { useState, useRef } from 'react';
import { subscribeGuest } from 'arts/config/api';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import Classes from './styles';
import ThanksPopup from '../subscribe_to_newsletter/thanks_popup';
import { onUserNewletterSubscribed } from '../../config/analytics';

const Subscribe = ({ email, customerEmailSHA256 }) => {
  const [loading, setLoading] = useState(false);
  const [subscribedModal, setSubscribed] = useState(false);
  const [subscribeButton, setSubscribeButton] = useState(true);
  const ref = useRef(null);

  const hideSubscribed = () => setSubscribed(false);

  const doSubscribe = () => {
    setLoading(true);
    subscribeGuest(email).then(() => {
      onUserNewletterSubscribed(customerEmailSHA256);
      if (!ref.current) { return; }
      setLoading(false);
      setSubscribed(true);
      setSubscribeButton(false);
    }).catch(() => {
      if (!ref.current) { return; }
      setLoading(false);
    });
  };

  let content = null;
  if (loading) {
    content = (
      <div className={ classnames(Classes.print, Classes.signUp) }>
        <Loader active inline style={ { marginTop: 24 } } />
      </div>
    );
  } else if (subscribedModal) {
    content = (
      <ThanksPopup onCancel={ hideSubscribed } />
    );
  } else if (subscribeButton) {
    content = (
      <div className={ classnames(Classes.print, Classes.signUp) }>
        <div>Sign up to receive emails about sales, new arrivals, and other fun things.</div>
        <div>
          <button type="button" className={ Classes.button } onClick={ doSubscribe }>
            SUBSCRIBE
          </button>
        </div>
      </div>
    );
  }

  return (
    <div ref={ ref }>
      { content }
    </div>
  );
};

Subscribe.propTypes = {
  email: PropTypes.string.isRequired,
  customerEmailSHA256: PropTypes.string.isRequired
};

export default Subscribe;
