import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Scroll from '../Application/scroll';
import AppClasses from '../Application/styles';
import Collection from './collection';
import { trackEvent } from '../../config/analytics';

const CollectionDetails = ({
  id, slug, name, loading, loadCollectionDetails, ...rest
}) => {
  useEffect(() => {
    if (!loading && !name) {
      loadCollectionDetails(slug);
    }
  }, []);

  useEffect(() => {
    if (!loading && id && name) {
      trackEvent('collection page', { 'collection id': id, name });
    }
  }, [
    id, name, loading
  ]);

  if (loading || !name) {
    return (
      <div className={ AppClasses.container20 }>
        <p>&nbsp;</p>
        <Loader active inline>Loading collection...</Loader>
        <Scroll />
      </div>
    );
  }

  return (
    <div className={ AppClasses.container20 }>
      <div className={ AppClasses.innerContainer20 }>
        <Scroll />
        <Collection id={ id } name={ name } { ...rest } key={ id } />
      </div>
    </div>
  );
};

CollectionDetails.propTypes = {
  loadCollectionDetails: PropTypes.func.isRequired,
  id: PropTypes.number,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string,
  loading: PropTypes.bool
};

CollectionDetails.defaultProps = {
  name: null,
  loading: false
};

export default CollectionDetails;
