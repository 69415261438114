import Constants from 'arts/config/constants';
import { loadWorksPageAPi, fetchArtFiltersApi } from 'arts/config/api';
import { scrollPageTo } from 'arts/config/lib';
import deepEqual from 'deep-equal';

const PER_PAGE = 210;
const REQUEST_PAGE_SIZE = 70;
const REQUEST_COUNTS = parseInt(PER_PAGE / REQUEST_PAGE_SIZE, 10);

const dispatchPagesLoaded = (page, responses, dispatch) => {
  let collection = [];
  let totalPages = null;
  responses.forEach(r => {
    const [data, headers] = r;
    collection = [...collection, ...data];
    totalPages = totalPages || Math.ceil(parseInt(headers.get('X-total-Pages'), 10) / REQUEST_COUNTS);
  });
  dispatch({
    type: Constants.Works.FETCH_SUCCESS,
    data: collection,
    totalPages,
    page
  });
};

export const fetchWorksPage = (params) => (dispatch, getState) => {
  const { params: paramsAre } = getState().works;
  if (deepEqual(params, paramsAre)) {
    return;
  }

  const page = parseInt(params.page, 10) || 1;

  dispatch({ type: Constants.Works.FETCH, params });
  scrollPageTo(0, 250).then(() => {
    const rqs = [];
    for (let i = 0; i < REQUEST_COUNTS; i += 1) {
      const pageBase = (page - 1) * REQUEST_COUNTS;
      const paramsWithPage = {
        ...params,
        page: pageBase + i + 1,
        per: REQUEST_PAGE_SIZE
      };
      rqs.push(loadWorksPageAPi(paramsWithPage));
    }
    Promise.all(rqs).then(responses => {
      const { params: paramsWere } = getState().works;
      if (!deepEqual(params, paramsWere)) { return; }
      dispatchPagesLoaded(page, responses, dispatch);
    }, errors => {
      dispatch({
        type: Constants.Works.FETCH_FAILURE,
        errors
      });
    });
  });
};

const artFiltersFetched = (filters) => ({
  type: Constants.ArtFilters.FETCH_SUCCESS,
  filters
});

export const fetchArtFilters = () => (dispatch) => {
  fetchArtFiltersApi().then(
    (data) => dispatch(artFiltersFetched(data))
  ).catch(() => {});
};
