import React, { PureComponent } from 'react';
import T from 'prop-types';
import Expandable from 'arts/components/expandable';
import Constants from 'arts/config/constants';
import { shippingDetailsItems } from 'arts/components/extra_info/shipping_details';
import { mat } from 'arts/components/PapersMenu/mounts';

export default class ExtraInfo extends PureComponent {
  static propTypes = {
    hasSelection: T.bool,
    aboutPrints: T.arrayOf(T.string)
  };

  static defaultProps = {
    hasSelection: false,
    aboutPrints: []
  }

  render() {
    const { aboutPrints, hasSelection } = this.props;
    if (!hasSelection) { return null; }

    const canvasFrames = window.currentLocale.currentLocale === 'uk' ? (
      <p>
        Canvas prints do not require a hanging wire and can be hung directly on a screw or nail.
      </p>
    ) : (
      <p>
        Our canvas prints are professionally framed with a woodgrain frame,
        designed specifically for framing canvas. The frame has a stepped inner
        edge to add subtle depth, and comes ready to hang with a hanging wire.
      </p>
    );

    const plexiGlass = window.currentLocale.currentLocale === 'uk' ? 'plexi-glass' : 'an archival UV plexi-glass';

    return (
      <div>
        <Expandable title="Prints" eKey={ Constants.Expandable.KEYS.prints }>
          { aboutPrints.map((v, i) => (<p key={ i }>{ v }</p>)) }
        </Expandable>
        <Expandable title="Frames" eKey={ Constants.Expandable.KEYS.frames }>
          <p>
            Our fine art paper prints and photographs are professionally framed
            with&nbsp;
            { plexiGlass }
            , and come ready to hang on your wall
            with a hanging wire. Prints can be framed with a&nbsp;
            { mat() }
            &nbsp;white mat,
            or without.
          </p>
          { canvasFrames }
        </Expandable>
        <Expandable title="Shipping" eKey={ Constants.Expandable.KEYS.shipping }>
          { shippingDetailsItems() }
        </Expandable>
      </div>
    );
  }
}
