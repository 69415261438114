import React from 'react';
import { Route } from 'react-router-dom';
import FineArtPrints from 'arts/components/fine_art_prints';
import WallArt from 'arts/components/wall_art';
import WallArts from 'arts/components/wall_arts';
import PopularSearchCategories from 'arts/components/popular_search_categories';
import NewArrivals from 'arts/components/new_arrivals';
import Category from 'arts/components/category';
import Style from 'arts/components/category/style';
import Color from 'arts/components/category/color';
import Medium from 'arts/components/category/medium';
import Orientation from 'arts/components/category/orientation';
import Price from 'arts/components/category/price';
import Size from 'arts/components/category/size';
import ArtistWorks from 'arts/components/ArtistWorks';
import Artists from 'arts/components/artists';
import WorksShow from 'arts/components/WorksShow';
import GiftCards from 'arts/components/gift_cards/gift_cards';
import GiftCard from 'arts/components/gift_cards/card/gift_card';
import GiftCardBalance from 'arts/components/gift_cards/balance';
import Product from 'arts/components/Product';
import ProductsList from 'arts/components/ProductsList';
import MyFavorites from 'arts/components/my_favorites';
import { Collections, DesignerPicks, GiftGuides } from 'arts/components/collections';
import CollectionDetails from 'arts/components/collection_details';
import Inspirations from 'arts/components/inspirations';
import Walls from 'arts/components/walls';
import Wall from 'arts/components/wall';
import MyWalls from 'arts/components/my_walls';
import OriginalArts from '../original_arts';
import OriginalArt from '../original_art';
import StaticPage from '../static_page/static_page';
import ReviewCartItems from '../checkout/review_cart_items';
import SignInToCheckout from '../checkout/sign_in_to_checkout';
import EditAddresses from '../checkout/edit_addresses';
import ThankYou from '../checkout/thank_you';
import EditOrder from '../edit_order';
import NewOrderPayment from '../new_order_payment';
import EditOrderPayment from '../edit_order_payment';
import QueryOrderStatus from '../query_order_status/query_order_status';
import Classes from './styles';
import VisitManager from './visit_manager';
import SubscribePopupCounters from '../subscribe_popup_counters';
import MyReferral from '../my_referral';
import PromoRewards from '../my_referral/promo_rewards';
import ReferralCode from '../referral_code';
import VisualSearch from '../visual_search';
import PromoSubscribe from '../promo_subscribe';
import Explore from '../explore';
import SearchQuery from '../search_query';

const Routes = () => (
  <>
    <VisitManager>
      {
        ({ visitsCount, pathname }) => (
          <SubscribePopupCounters visitsCount={ visitsCount } pathname={ pathname } />
        )
      }
    </VisitManager>
    <div className={ Classes.root }>
      <Route exact path="/query_order_status" component={ QueryOrderStatus } />

      <Route exact path="/trios" component={ ProductsList } />
      <Route exact path="/trios/:id" component={ Product } />

      <Route exact path="/stylist/walls" component={ MyWalls } />
      <Route exact path="/my/favorites" component={ MyFavorites } />

      <Route exact path="/walls" component={ Walls } />
      <Route exact path="/walls/style/:with_home_decor_style_id" component={ Walls } />
      <Route exact path="/walls/room/:with_room_id" component={ Walls } />
      <Route exact path="/walls/:id" component={ Wall } />
      <Route exact path="/walls/:id/:slug" component={ Wall } />

      <Route exact path="/popular-search-categories" component={ PopularSearchCategories } />

      <Route exact path="/wall-art" component={ WallArts } />
      <Route exact path="/wall-art/:slug" component={ WallArt } />

      <Route exact path="/art-prints/category/:categorySlug" component={ Category } />
      <Route exact path="/art-prints/style/:styleSlug" component={ Style } />
      <Route exact path="/art-prints/style/:styleSlug/:categorySlug" component={ Style } />
      <Route exact path="/art-prints/medium/:mediumSlug" component={ Medium } />
      <Route exact path="/art-prints/price/:priceSlug" component={ Price } />
      <Route exact path="/art-prints/:colorSlug" component={ Color } />
      <Route exact path="/art-prints/size/:sizeSlug" component={ Size } />
      <Route exact path="/art-prints/orientation/:orientationSlug" component={ Orientation } />

      <Route exact path="/new-arrivals" component={ NewArrivals } />
      <Route exact path="/art-prints/product/:workSlug" component={ WorksShow } />
      <Route exact path="/art-prints" component={ FineArtPrints } />

      <Route exact path="/original_arts/:id/:slug" component={ OriginalArt } />
      <Route exact path="/original_arts/:id" component={ OriginalArt } />
      <Route exact path="/original_arts" component={ OriginalArts } />

      <Route exact path="/gift_cards" component={ GiftCards } />
      <Route exact path="/gift_cards/card" component={ GiftCard } />
      <Route exact path="/gift_cards/balance_inquiry" component={ GiftCardBalance } />

      <Route exact path="/artists" component={ Artists } />
      <Route exact path="/artists/:artistId" component={ ArtistWorks } />
      <Route exact path="/users/:artistId" component={ ArtistWorks } />
      <Route exact path="/artists/:artistId/:slug" component={ ArtistWorks } />
      <Route exact path="/users/:artistId/:slug" component={ ArtistWorks } />

      <Route exact path="/collections" component={ Collections } />
      <Route exact path="/inspirations" component={ Inspirations } />
      <Route exact path="/designer_picks" component={ DesignerPicks } />
      <Route exact path="/gift_guides" component={ GiftGuides } />
      <Route exact path="/collections/:slug" component={ CollectionDetails } />

      <Route exact path="/cart_items" component={ ReviewCartItems } />
      <Route exact path="/my/order" component={ SignInToCheckout } />
      <Route exact path="/my/address/edit" component={ EditAddresses } />
      <Route exact path="/orders/:id/edit" component={ EditOrder } />
      <Route exact path="/orders/:id/payment/new" component={ NewOrderPayment } />
      <Route exact path="/orders/:id/payment" component={ NewOrderPayment } />
      <Route exact path="/orders/:id/payment/edit" component={ EditOrderPayment } />
      <Route exact path="/orders/:id/thankyou" component={ ThankYou } />

      <Route exact path="/about" component={ StaticPage } />
      <Route exact path="/contact_us" component={ StaticPage } />
      <Route exact path="/sell_with_us" component={ StaticPage } />
      <Route exact path="/artist_faq" component={ StaticPage } />
      <Route exact path="/artist_agreement" component={ StaticPage } />
      <Route exact path="/copyright_policy" component={ StaticPage } />
      <Route exact path="/shipping_info" component={ StaticPage } />
      <Route exact path="/return_policy" component={ StaticPage } />
      <Route exact path="/terms_of_use" component={ StaticPage } />
      <Route exact path="/privacy" component={ StaticPage } />
      <Route exact path="/customer_faq" component={ StaticPage } />
      <Route exact path="/technical_guidelines" component={ StaticPage } />
      <Route exact path="/affiliate_agreement" component={ StaticPage } />
      <Route exact path="/revshare" component={ StaticPage } />

      <Route exact path="/my/referral" component={ MyReferral } />
      <Route exact path="/my/referral/rewards" component={ PromoRewards } />
      <Route exact path="/ref/:token" component={ ReferralCode } />

      <Route exact path="/more-like-this/:id" component={ VisualSearch } />

      <Route exact path="/get-promo-code" component={ PromoSubscribe } />
      <Route exact path="/visual-search" component={ Explore } />
      <Route exact path="/visual-search/:slug" component={ SearchQuery } />
    </div>
  </>
);

export default Routes;
