import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { request, toCamelCase } from 'arts/../shared/request';
import WorkListItem from 'arts/components/WorkListItem';
import Right from 'arts/assets/chevron_right';
import AppClasses from '../Application/styles';
import GTMPageView from '../Application/data_layer';
import Scroll from '../Application/scroll';
import { onWorkItems, onWorkClick, trackEvent } from '../../config/analytics';
import Classes from './styles';
import CategoryDescription from '../category/category_description';

import { CategoryPrints } from '../category/header';

const GTM_BATCH_SIZE = 20;

const WallArt = () => {
  const { slug } = useParams();
  const [seoCategory, setSeoCategory] = useState(null);

  useEffect(() => {
    if (window.serializedCategory) {
      setSeoCategory(toCamelCase(window.serializedCategory));
    } else {
      request(`/api/wall-art/${ slug }`)
        .then((data) => {
          setSeoCategory(toCamelCase(data));
        }).catch(() => {});
    }
  }, []);

  const {
    works, title, color, imageHref,
    footerColor, footerText, footerImageHref, footerHref,
    subTitle, description
  } = seoCategory || {};

  useEffect(() => {
    if (title) {
      trackEvent('seo category page', { title });
    }
  }, [title]);

  const gtm = title ? (
    <GTMPageView pageType="category" section={ title } />
  ) : null;

  const gtmListName = slug;

  const onItemClick = (position) => onWorkClick(works[position], 'category', gtmListName, position);

  const loader = seoCategory ? null : (
    <div className={ Classes.loading }>
      <Loader inline active content="Loading Art..." />
    </div>
  );

  const onAppear = (position) => {
    if (gtmListName) {
      const items = works.slice(position, position + GTM_BATCH_SIZE);
      onWorkItems({ items, listName: gtmListName, pageType: 'category' });
    }
  };

  const renderHeader = () => {
    if (!imageHref) {
      return (
        <div className={ Classes.legacyHeader }>
          <h1>{ title }</h1>
        </div>
      );
    }

    return (
      <div
        className={ classnames(AppClasses.headerText, Classes.header) }
        style={ { backgroundColor: `#${ color }` } }
      >
        <div className={ Classes.hleft }>
          <h1>{ title }</h1>
          <CategoryPrints />
        </div>
        <div className={ Classes.hright }>
          <img src={ imageHref } alt={ title } />
        </div>
      </div>
    );
  };

  const renderFooter = () => (
    <a
      className={ classnames(AppClasses.footerText, Classes.footer) }
      style={ { backgroundColor: `#${ footerColor }` } }
      href={ `${ window.currentLocale.basename }${ footerHref }` }
    >
      <div className={ Classes.hleft }>
        <img src={ footerImageHref } alt={ footerText } />
      </div>
      <div className={ Classes.hright }>
        <div className={ Classes.text }>
          <h3>{ footerText }</h3>
          <Right />
        </div>
        <div className={ Classes.under } />
      </div>
    </a>
  );

  const renderWorks = works ? (
    <>
      { renderHeader() }
      <CategoryDescription description={ subTitle } skipShowMore />
      <ul className={ AppClasses.gridContainer3inRow }>
        {
          works.map((w, index) => (
            <WorkListItem
              data={ w }
              key={ w.id }
              loading={ false }
              onAppear={ index % GTM_BATCH_SIZE === 0 ? onAppear : null }
              position={ index }
              onClick={ onItemClick }
            />
          ))
        }
      </ul>
      <CategoryDescription description={ description } />
      { renderFooter() }
    </>
  ) : null;

  return (
    <div className={ AppClasses.container20 }>
      { gtm }
      <Scroll />
      { loader }
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        { renderWorks }
      </div>
    </div>
  );
};

export default WallArt;
