import React, { useEffect, useRef } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import InfiniteScroll from 'arts/components/InfiniteScroll';
import Loader from 'arts/components/Loader';
import Wall from './wall';
import WallsContainer from './container';
import NoResults from './no_results';
import { trackEvent } from '../../config/analytics';

import AppClasses from '../Application/styles';
import { humanizeParams } from '../works/works';
import WallsList from '../walls/list';

// eslint-disable-next-line react/prop-types
const ParentContainer = (parentRef) => ({ children }) => (
  <WallsContainer ref={ parentRef }>
    { children }
  </WallsContainer>
);

const WallsAsGrid = ({
  walls,
  loading,
  hasMore,
  fetchWalls,
  resetWalls,
  fetchWallFilters,
  wallFilters,
  page,
  history,
  activeFilters
}) => {
  const canFetch = () => !loading && hasMore;

  const parentRef = useRef(null);

  const loadNextPage = () => {
    if (!canFetch()) { return; }
    fetchWalls(page + 1, activeFilters);
  };

  const renderWall = (wall, position) => {
    const href = `${ wall.href }?as=thumb&${ queryString.stringify({ ...activeFilters }) }`;
    return (
      <Wall
        key={ wall.id }
        { ...wall }
        parentRef={ parentRef }
        href={ href }
        position={ position }
      />
    );
  };

  useEffect(() => {
    if (!loading && walls.length && wallFilters) {
      const humanParams = humanizeParams(activeFilters, wallFilters);
      trackEvent('walls center', { ...humanParams, as: 'grid', 'walls count': walls.length });
    }
  }, [loading, walls.length, wallFilters]);

  return (
    <WallsList
      fetchWallFilters={ fetchWallFilters }
      resetWalls={ resetWalls }
      wallFilters={ wallFilters }
      activeFilters={ activeFilters }
      history={ history }
      loadNextPage={ loadNextPage }
    >
      <div className={ AppClasses.innerContainer20 }>
        <InfiniteScroll
          items={ walls }
          itemRenderer={ renderWall }
          onLoad={ loadNextPage }
          threshold={ 728 }
          itemsContainer={ ParentContainer(parentRef) }
          forwardRef={ parentRef }
        />
        {
          loading
            ? <Loader text="Loading more items, hold on for a sec..." />
            : null
        }
        <NoResults loading={ loading } walls={ walls } />
      </div>
    </WallsList>
  );
};

WallsAsGrid.propTypes = {
  fetchWalls: PropTypes.func.isRequired,
  resetWalls: PropTypes.func.isRequired,
  fetchWallFilters: PropTypes.func.isRequired,
  // walls
  loading: PropTypes.bool.isRequired,
  walls: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  // filters
  wallFilters: PropTypes.arrayOf(PropTypes.any),
  activeFilters: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired
};

WallsAsGrid.defaultProps = {
  wallFilters: null
};

export default WallsAsGrid;
