import React, { Suspense } from 'react';
import { Loader } from 'semantic-ui-react';

const SearchQuery = React.lazy(() => import('./search_query'));

export default () => (
  <Suspense fallback={ <Loader active inline /> }>
    <SearchQuery />
  </Suspense>
);
